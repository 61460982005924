<template>
  <moe-page title="售后订单" class="orderPage">
    <!-- 筛选信息 -->
    <moe-inquire @search="refundOrderSearch">
      <el-form-item label="退款编号">
        <el-input class="w-250" v-model="refundParams.refundNo" placeholder="退款编号" clearable></el-input>
      </el-form-item>
      <el-form-item label="退款状态">
        <!-- <moe-select type="refundStatus" v-model="refundParams.status" placeholder="选择退款状态" clearable></moe-select> -->
        <el-select class="w-250" v-model="refundParams.status" placeholder="选择退款状态" clearable>
          <el-option v-for="(item, index) in REFUND_STATE_LIST" :key="index" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="售后方式">
        <moe-select type="refundTypeList" v-model="refundParams.type" placeholder="选择售后方式"></moe-select>
      </el-form-item>
      <el-form-item label="订单编号">
        <el-input class="w-250" v-model="refundParams.orderNo" placeholder="订单编号" clearable></el-input>
      </el-form-item>
      <el-form-item label="用户编号">
        <el-input :value="refundParams.userId" @input="(value) => refundParams.userId = $moe_formatter.formatterId(value)" placeholder="输入用户编号" maxlength="100" clearable />
      </el-form-item>
      <el-form-item label="用户名称">
        <el-input v-model.trim="refundParams.userName" placeholder="输入用户名称" maxlength="100" clearable />
      </el-form-item>
      <el-form-item label="用户手机号">
        <el-input v-model.trim="refundParams.userPhone" placeholder="输入用户手机号" maxlength="100" clearable />
      </el-form-item>
      <el-form-item label="商品名称">
        <el-input class="w-250" v-model.trim="refundParams.goodsName" placeholder="输入商品名称" maxlength="100" clearable />
      </el-form-item>
      <el-form-item label="商品编号">
        <el-input :value="refundParams.goodsId" @input="(value) => refundParams.goodsId = $moe_formatter.formatterId(value)" placeholder="输入商品编号" maxlength="100" clearable />
      </el-form-item>
      <el-form-item label="申请时间">
        <el-date-picker
          placement="bottom-start"
          v-model="dateTime"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          @change="(datetime) => ([refundParams.startTime, refundParams.endTime] = datetime || ['', ''])" />
      </el-form-item>
    </moe-inquire>

    <moe-table ref="refundOrderTable" url="/shop/order/orderrefundList" :params="refundParams" :numberShow="false" class="content">
      <el-table-column type="selection" width="55" />
      <el-table-column width="1" class="box">
        <template slot-scope="{ row }">
          <div class="orderNum tal df">
            <span class="ml-70">退款编号：{{ row.refundNo }}</span>
            <span class="ml-70">订单编号：{{ row.orderNo }}</span>
            <span class="ml-70">商品编号：{{ row.goodsId }}</span>
            <span class="ml-30"><moe-icon name="avatar" class="font-16"></moe-icon></span>
            <span class="ml-5">{{ row.userPhone }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="orderItem" label="商品信息" min-width="300">
        <template slot-scope="{ row }">
          <div class="df pt-10 pb-10">
            <moe-image :src="row.coverUrl" class="imgSty mr-10"/>
            <div class="df1 tal">
              <p class="fwb">{{ row.goodsName }}</p>
              <p class="font-12 color-info">{{ row.specValueStr }}</p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="totalPrice" label="交易金额" min-width="120">
        <template slot-scope="{ row }">
          ¥ {{ row.totalPrice }}
        </template>
      </el-table-column>
      <el-table-column prop="money" label="退款金额" min-width="120">
        <template slot-scope="{ row }">
          ¥ {{ row.money }}
        </template>
      </el-table-column>
      <el-table-column label="申请时间" width="150">
        <template slot-scope="{ row }">
          {{ $moe_time.getTimeStamp(row.createTime) }}
        </template>
      </el-table-column>
      <el-table-column label="售后方式" width="150">
        <template slot-scope="{ row }">
          {{ $moe_format.getRefundType(row.type) }}
        </template>
      </el-table-column>
      <el-table-column label="原因" prop="afterSalesCause" :formatter="afterSalesCause" min-width="150" />
      <el-table-column label="收货状态" prop="expressStatus" :formatter="expressStatus" min-width="100" />
      <el-table-column label="退货状态" prop="returnStatus" :formatter="returnStatus" min-width="100" />
      <el-table-column label="退款状态" prop="status" :formatter="status" :show-overflow-tooltip="true" min-width="200" />
      <el-table-column label="操作" width="150">
        <div class="moe-table__btns" slot-scope="{ row }">
          <el-button type="success" size="mini" icon="el-icon-document" @click="$router.push(`/refundOrder/detail?id=${row.id}`)">查看详情</el-button>
        </div>
      </el-table-column>
    </moe-table>
  </moe-page>
</template>

<script>
import { REFUND_STATE_LIST, REFUND_STATE_OPTIONS } from '@/views/Order/Order.data';
export default {
  name: 'RefundOrder',
  data() {
    let refundParams = {
      pageNum: 1,
      pageSize: 10,
      refundNo: '',
      orderNo: '',
      status: '',
      type: '',
      userId: '',
      userName: '',
      userPhone: '',
      goodsName: '',
      goodsId: '',
      startTime: '',
      endTime: '',
    }
    return {
      refundParams,
      dateTime: [],
      refundReasonList: [],
      REFUND_STATE_LIST,
      REFUND_STATE_OPTIONS
    }
  },
  methods: {
    refundOrderSearch(isSearch) {
      if (!isSearch) {
        this.refundParams = {
          pageNum: 1,
          pageSize: 10,
          refundNo: '',
          orderNo: '',
          status: '',
          type: '',
          userId: '',
          userName: '',
          userPhone: '',
          goodsName: '',
          goodsId: '',
          startTime: '',
          endTime: '',
        }

        this.dateTime = [];
      }
      this.$refs['refundOrderTable'].searchData();
    },
    /** 获取拒绝原因列表 */
    queryShopRefundReasons() {
      this.$moe_api.ORDER_API.queryShopRefundReasons({ type: 1 }).then((data) => {
        if (data.code === 200) {
          this.refundReasonList = data.result;
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 用来格式化内容 */
    afterSalesCause(row) {
      let findItem = this.refundReasonList.find(({ id }) => row.afterSalesCause === id);
      if (findItem) {
        return findItem.reason;
      } else {
        return '-';
      }
    },
    expressStatus(row) {
      let findItem = this.$moe_data.expressStatus.find(({ value }) => row.expressStatus === value);
      if (findItem) {
        return findItem.label;
      } else {
        return '-';
      }
    },
    returnStatus(row) {
      if (!row.returnStatus || row.type === 1) return '-';
      if (row.returnStatus === '1') return '已发货';
      if (row.returnStatus === '0') return '未发货';
    },
    status({ status }) {
      return this.REFUND_STATE_OPTIONS[status] || '-';
    }
  },
  mounted() {
    this.queryShopRefundReasons();
  }
}
</script>

<style lang="scss">
.orderPage {
  .el-table--fit {
    border-bottom: 1px solid #EBEEF5;
  }

  .orderNum {
    width: 100vw;
    background: rgba(223, 166, 99, 0.05);
    position: absolute;
    top: 0;
    left: -55px;
    z-index: 1;
    height: 50px;
    line-height: 50px;
  }

  .imgSty {
    width: 78px;
    display: block;
  }

  .mesStyTac {
    justify-content: center;
  }

  .content {
    .el-table--medium {
      .el-table__cell {
        padding-top: 70px;
        border-bottom: 0;
      }
    }

    .moe-table__head {
      .el-table__cell {
        padding: 10px 0;
      }
    }

    .el-table__row td:nth-child(1) .cell {
      position: absolute !important;
      top: 12px !important;
      left: 50%;
      transform: translateX(-50%);
      z-index: 999;
    }
  }
}</style>