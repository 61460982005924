/** 售后状态list */
export const REFUND_STATE_LIST = [
  {
    label: '全部',
    value: '',
  },
  {
    label: '退款待处理',
    value: 'REFUND_PENDING',
  },
  {
    label: '商家拒绝退款,待买家处理',
    value: 'REFUND_REJECT',
  },
  {
    label: '待买家发货',
    value: 'RETURN_IN_PROGRESS',
  },
  {
    label: '商家拒绝收货,待买家处理',
    value: 'WAITING_RESPONSE',
  },
  {
    label: '待商家收货',
    value: 'RETURN_REVIEW',
  },
  {
    label: '退款成功',
    value: 'REFUND_SUCCESSFUL',
  },
  {
    label: '退款关闭',
    value: 'REFUND_COMPLETED',
  },
];

/** 售后状态options */
export const REFUND_STATE_OPTIONS = {
  REFUND_PENDING: '退款待处理',
  REFUND_REJECT: '商家拒绝退款,待买家处理',
  RETURN_IN_PROGRESS: '待买家发货',
  WAITING_RESPONSE: '商家拒绝收货,待买家处理',
  RETURN_REVIEW: '待商家收货',
  REFUND_SUCCESSFUL: '退款成功',
  REFUND_COMPLETED: '退款关闭',
};

export default {
  REFUND_STATE_LIST,
  REFUND_STATE_OPTIONS,
};